import React, { useEffect, useState } from "react";
import {
  BsGrid1X2Fill,
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsListCheck,
  BsCurrencyDollar
} from "react-icons/bs";
import { MdAccountBalance } from "react-icons/md";
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import "./Dash.css";

function Sidebar({ openSidebarToggle }) {
  const [userName, setUserName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(true);
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setIsAdmin(decodedToken.isAdmin);
      setUserEmail(decodedToken.email);
    } else {
      setIsTokenValid(false);
    }
  }, [token]);

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    if (storedUserName) {
      setUserName(storedUserName);
    }
  }, []);

  const handleUsers = (event) => {
    event.preventDefault();
    if (!isAdmin) {
      alert("You do not have permission to access this page.");
      return;
    }
    navigate("/dashboard/users");
  };

  if (!isTokenValid) {
    return null;
  }

  const isActive = (path) => location.pathname === path;
  return (
    <aside
      id="sidebar"
      className={openSidebarToggle ? "sidebar-responsive" : ""}
    >
      <div className="sidebar-title">
        <div className="sidebar-brand">
          <div className="header">{userName}</div>
        </div>
      </div>

      <ul className="sidebar-list">
        <Link
          to="/dashboard"
          style={{ textDecoration: "none", color: "#9e9ea4" }}
        >
          <li
            className={`sidebar-list-item ${
              isActive("/dashboard") ? "active" : ""
            }`}
          >
             Dashboard
          </li>
        </Link>
        {isAdmin ? (
          <Link
            to="/dashboard/subscriptions"
            style={{ textDecoration: "none", color: "#9e9ea4" }}
          >
            <li
              className={`sidebar-list-item ${
                isActive("/dashboard/subscriptions") ? "active" : ""
              }`}
            >
               Subscriptions
            </li>
          </Link>
        ) : (
          <>
          <Link
            to="/dashboard/subscriptions"
            style={{ textDecoration: "none", color: "#9e9ea4" }}
          >
            <li
              className={`sidebar-list-item ${
                isActive("/dashboard/subscriptions") ? "active" : ""
              }`}
            >
               My Subscriptions
            </li>
          </Link>
          <Link
            to="/dashboard/recharge-request"
            style={{ textDecoration: "none", color: "#9e9ea4" }}
          >
            <li
              className={`sidebar-list-item ${
                isActive("/dashboard/recharge-request") ? "active" : ""
              }`}
            >
               Recharge Request
            </li>
          </Link>
          </>
        )}
        <Link
          to="/dashboard/categories"
          style={{ textDecoration: "none", color: "#9e9ea4" }}
        >
          <li
            className={`sidebar-list-item ${
              isActive("/dashboard/categories") ? "active" : ""
            }`}
          >
             Our Products
          </li>
        </Link>
        {isAdmin ? (
          <a
            href=""
            onClick={handleUsers}
            style={{ textDecoration: "none", color: "#9e9ea4" }}
          >
            <li
              className={`sidebar-list-item ${
                isActive("/dashboard/users") ? "active" : ""
              }`}
            >
               Users
            </li>
          </a>
        ) : null}
        <Link
          to="/dashboard/invoices"
          style={{ textDecoration: "none", color: "#9e9ea4" }}
        >
          <li
            className={`sidebar-list-item ${
              isActive("/dashboard/invoices") ? "active" : ""
            }`}
          >
             Invoices
          </li>
        </Link>
        {isAdmin ? (
          <>
        <Link to="/dashboard/comission"  style={{ textDecoration: "none", color: "#9e9ea4" }}>
        <li
            className={`sidebar-list-item ${
              isActive("/dashboard/comission") ? "active" : ""
            }`}
          >
           Processing Fee
          </li>
        </Link>
        <Link
          to="/dashboard/accountdetails"
          style={{ textDecoration: "none", color: "#9e9ea4" }}
        >
          <li
            className={`sidebar-list-item ${
              isActive("/dashboard/accountdetails") ? "active" : ""
            }`}
          >
             Account Details
          </li>
        </Link>
        <Link
          to="/dashboard/wallet-recharge-requests"
          style={{ textDecoration: "none", color: "#9e9ea4" }}
        >
          <li
            className={`sidebar-list-item ${
              isActive("/dashboard/wallet-recharge-requests") ? "active" : ""
            }`}
          >
          Recharge Request
          </li>
        </Link>
        <Link
          to="/dashboard/coupons"
          style={{ textDecoration: "none", color: "#9e9ea4" }}
        >
          <li
            className={`sidebar-list-item ${
              isActive("/dashboard/coupons") ? "active" : ""
            }`}
          >
          Coupons
          </li>
        </Link>
        <Link
          to="/dashboard/demodata"
          style={{ textDecoration: "none", color: "#9e9ea4" }}
        >
          <li
            className={`sidebar-list-item ${
              isActive("/dashboard/demodata") ? "active" : ""
            }`}
          >
          Demo Data
          </li>
        </Link>
        <Link
          to="/dashboard/faqs"
          style={{ textDecoration: "none", color: "#9e9ea4" }}
        >
          <li
            className={`sidebar-list-item ${
              isActive("/dashboard/faqs") ? "active" : ""
            }`}
          >
            <AiOutlineQuestionCircle className="icon" /> Faqs
          </li>
        </Link>
        </>
        ):(null)
      }
      </ul>
    </aside>
  );
}

export default Sidebar;
