import React, { useEffect, useState } from 'react';
import './GuideFaqs.css';
import HeaderEbill from "./HeaderEbill";
import { MdExpandLess,MdExpandMore } from "react-icons/md";
import ScrollToTop from './ScrollTop';
import FooterEbill from './FooterEbill';


function GuideFaqs() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqs,setFaqs]=useState([])
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const [videos, setVideos] = useState([]); 

  // Fetch videos from backend API
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/videos`); 

        if (!response.ok) {
          throw new Error('Failed to fetch videos');
        }

        const data = await response.json(); 
        setVideos(data); 
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        // Make the GET request to the API endpoint
        const response = await fetch(`${BASE_URL}/api/faqs`);
        
        // Check if the response is successful
        if (!response.ok) {
          throw new Error("Failed to fetch FAQs");
        }
    
        // Parse the JSON response
        const data = await response.json();
        
        // Set the fetched FAQs in the state
        setFaqs(data);
      } catch (error) {
        // Log any errors that occur
        console.error("Error fetching FAQs:", error);
      }
    };
    
    // Call the function to fetch the FAQs when the component mounts
    fetchFaqs();
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts
  

  return (
    <>
    <HeaderEbill/>
    <ScrollToTop/>
    <div className='guides'>
        <h1 style={{color:"#0f4b60"}}>Guides & FAQs</h1>
        <div className="line"></div>
      {/* YouTube Videos Section */}
      <div className="video-section">
      {videos.length > 0 ? (
        videos.map((video, index) => (
          <div key={index} className="video-item">
            <h4>{video.title}</h4>
            <p>{video.description}</p>
            <iframe
              src={video.url}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        ))
      ) : (
        <p>No videos available</p>
      )}
    </div>
      {/* FAQ Section */}
      <section>
      <div className="accordion">
        <div className="hede">
          <h1>Frequently Asked Questions</h1>
        </div>
        <br />
        {faqs.length > 0 ? (
          faqs.map((faq, index) => (
            <div
              key={index}
              className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
              style={{ border: 'none' }}
            >
              <h2 className="accordion-header" onClick={() => toggleAccordion(index)}>
                {faq.question}
                <span className="icon">
                  {activeIndex === index ? (
                    <MdExpandLess style={{ fontSize: '30px',color:"#0f4b60" }} />
                  ) : (
                    <MdExpandMore style={{ fontSize: '30px' }} />
                  )}
                </span>
              </h2>
              <div className="accordion-content">
                <p>{faq.answer}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No FAQs available</p>
        )}
      </div>
    </section>
    </div>
    <FooterEbill/>
    </>
  );
}

export default GuideFaqs;
