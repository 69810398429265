import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./EditUser.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditUser() {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const { userEmail } = location.state;

  const [user, setUser] = useState({
    name: "",
    email: "",
    new_email: "", 
    phone: "",
    address: "",
    wallet_balance: "",
    status: 0,
    password: "",
  });
  const [originalUser, setOriginalUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${BASE_URL}/edit/${userEmail}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (response.ok) {
          setUser({ ...data.user, new_email: data.user.email }); 
          setOriginalUser(data.user); 
        } else {
          toast.error(data.error || "Failed to fetch user data.");
        }
      } catch (err) {
        console.error("Error:", err.message);
        toast.error("An error occurred while fetching user data.");
      }
    };
    fetchUser();
  }, [userEmail, BASE_URL]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const modifiedUser = { ...user, email: undefined }; 
    if (
      JSON.stringify(modifiedUser) ===
      JSON.stringify({ ...originalUser, new_email: originalUser.email })
    ) {
      toast.warn("No changes were made.");
      return;
    }
  
    const userData = {
      name: user.name,
      email: user.email, 
      new_email: user.new_email, 
      phone: user.phone,
      address: user.address,
      wallet_balance: user.wallet_balance,
      status: user.status,
      password: user.password,
    };
  
    try {
      const response = await fetch(`${BASE_URL}/update/${userEmail}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      const result = await response.json();
  
      if (response.ok) {
        toast.success(result.message);
        navigate(-1);
      } else {
        toast.error(result.error || "Failed to update user.");
      }
    } catch (err) {
      console.error("Error:", err.message);
      toast.error("An error occurred while updating the user.");
    }
  };

  return (
    <div className="edit-contain">
      <h2>Edit User: {user.name}</h2>
      <div className="container-edit">
        <form onSubmit={handleUpdate} className="edit-form">
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={user.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>Current Email:</label>
            <input
              type="email"
              value={user.email}
              disabled
            />
          </div>

          <div className="form-group">
            <label>New Email:</label>
            <input
              type="email"
              value={user.new_email}
              onChange={(e) => setUser({ ...user, new_email: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>Phone:</label>
            <input
              type="tel"
              value={user.phone}
              onChange={(e) => setUser({ ...user, phone: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              placeholder="Leave blank to keep current password"
            />
          </div>

          <div className="form-group">
            <label>Address:</label>
            <textarea
              value={user.address}
              onChange={(e) => setUser({ ...user, address: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label>Wallet Balance:</label>
            <input
              type="text"
              value={user.wallet_balance}
              onChange={(e) => setUser({ ...user, wallet_balance: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label>Status:</label>
            <select
              value={user.status}
              onChange={(e) =>
                setUser({ ...user, status: Number(e.target.value) })
              }
            >
              <option value={1}>Enabled</option>
              <option value={0}>Disabled</option>
            </select>
          </div>
          <div className="save-back-btn-class">
            <button type="button" className="back-btn" onClick={() => navigate(-1)}>
              Back
            </button>
            <button type="submit" className="submit-button">
              Save Changes
            </button>
          </div>
        </form>
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  );
}

export default EditUser;
