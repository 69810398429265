import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Subscriptions.css";
import { FaEye } from "react-icons/fa";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

function Products() {
  const [products, setProducts] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [filteredSubscription, setFilteredSubscription] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setIsAdmin(decodedToken.isAdmin);
      setUserEmail(decodedToken.email);
    }

    const fetchProducts = async () => {
      try {
        const url = isAdmin
          ? `${BASE_URL}/allproducts`
          : `${BASE_URL}/products/${userEmail}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (response.ok) {
          setProducts(data.products);
          setFilteredSubscription(data.products);
        } else {
          console.error(data.message);
        }
      } catch (err) {
        console.error("Error fetching products:", err.message);
      }
    };

    fetchProducts();
  }, [userEmail, isAdmin, BASE_URL]);

  useEffect(() => {
    applyFilters();
  }, [filterText, filterStatus, products]);

  const applyFilters = () => {
    const filtered = products.filter((product) => {
      const matchesName =
        filterText === "" ||
        product.name?.toLowerCase().includes(filterText.toLowerCase());
      const matchesStatus =
        filterStatus === "" ||
        product.status?.toLowerCase().includes(filterStatus.toLowerCase());

      return matchesName && matchesStatus;
    });

    setFilteredSubscription(filtered);
  };


  return (
    <div className="products">
      {isAdmin ? (
        <>
          <h2>Total Subscriptions</h2>
          <div className="header-container-sub">
            <div style={{display:"flex",gap:"10px"}}>
          <Link to="/dashboard/add_products">
            <button className="add-user-btn">Add New</button>
          </Link>
          <Link to="/dashboard/import">
          <button className="add-user-btn">Import</button>
          </Link>
          </div>
          <div className="filter-section">
            <input
              type="text"
              placeholder="Search by Name"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <input
              type="text"
              placeholder="Search by Status"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            />
            </div>
          </div>
        </>
      ) : (
        <>
        <h2>My Subscriptions</h2>
        <div className="header-container-sub">
        <Link to="/dashboard/categories">
            <button className="add-user-btn" style={{marginBottom:"5px"}}>Buy New</button>
          </Link>
          </div>
          </>
      )}
      <div className="subscriptions-table">
        <table className="user-table">
          <thead>
            <tr>
              <th>S No</th>
              {isAdmin ? <th>Name</th> : null}
              <th>Package</th>
              <th>Domain Name</th>
              <th>Expiry Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredSubscription.length === 0 ? (
              <tr>
                <td colSpan={isAdmin ? 7 : 6}>No products found</td>
              </tr>
            ) : (
              filteredSubscription.map((product, index) => (
                <tr key={product.subscription_id} className="product-row">
                  <td>{index+1}</td>
                  {isAdmin ? <td>{product.name}</td> : null}
                  <td>{product.plan_name}</td>
                  <td>{product.domain_name}</td>
                  <td>{product.expiry_date}</td>
                  <td>
                    <span
                      style={{
                        display: "inline-block",
                        borderRadius: "4px",
                        padding: "3px 12px",
                        fontWeight: "bold",
                        color:
                          product.status === "active"
                            ? "#4caf50"
                            : product.status === "suspended"
                            ? "#9e9e9e"
                            : product.status === "progress"
                            ? "#2196F3"
                            : product.status === "create"
                            ? "Orange"
                            : "#8b4513",
                        border: `2px solid ${
                          product.status === "active"
                            ? "#4caf50"
                            : product.status === "suspended"
                            ? "#9e9e9e"
                            : product.status === "progress"
                            ? "#2196F3"
                            : product.status === "create"
                            ? "Orange"
                            : "#8b4513"
                        }`,
                      }}
                    >
                      {product.status.toUpperCase()}
                    </span>
                  </td>
                  <td>
                    <div className="details-container" style={{ marginLeft: "-10px" }}>
                      <Link
                        to="/dashboard/details"
                        state={{
                          subscription_id: product.subscription_id,
                          name: product.name,
                          email:product.email,
                        }}
                      >
                        <button className="details-btn">
                          <FaEye style={{ marginRight: "5px" }} />
                          Details
                        </button>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Products;
