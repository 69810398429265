import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import "./RechargeRequest.css"; 

const RechargeRequest = () => {
  const [walletBalance, setWalletBalance] = useState(null);
  const [rechargeHistory, setRechargeHistory] = useState([]);
  const [error, setError] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get("token");

  // Function to return color based on status
  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "green";
      case "rejected":
        return "red";
      case "pending":
        return "orange";
      default:
        return "black";
    }
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserEmail(decodedToken.email);
    }
  }, [token]);

  useEffect(() => {
    if (userEmail) {
      fetchWallet();
      fetchRechargeHistory();
    }
  }, [userEmail]);

  const fetchWallet = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get/wallet/${userEmail}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (response.ok) {
        setWalletBalance(result.wallet);
      } else {
        alert(result.error);
      }
    } catch (err) {
      console.error(err);
      setError("Error fetching wallet balance");
    }
  };

  const fetchRechargeHistory = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/wallet/rechargehistory/${userEmail}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (response.ok) {
        setRechargeHistory(result.history);
      } else {
        alert(result.error || "Failed to fetch recharge history");
      }
    } catch (err) {
      console.error(err);
      setError("Error fetching recharge history");
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="recharge-container">
      <h2 className="recharge-header">Recharge Request</h2>

      {/* Wallet Balance */}
      <div className="wallet-card-container">
        <div className="wallet-card">
          <h3 className="wallet-title">Available Wallet Balance</h3>
          <p className="wallet-balances">
            {walletBalance !== null ? `${walletBalance} SAR` : "Loading..."}
          </p>
        </div>
      </div>

      {/* Recharge History */}
      <div className="recharge-history-container">
        <h3 className="recharge-history-title">Recharge History</h3>
        <div className="recharge-history-table-container">
          <table className="recharge-table">
            <thead>
              <tr>
                <th>S No.</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {rechargeHistory.length > 0 ? (
                rechargeHistory.map((entry, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{new Date(entry.created_at).toLocaleString()}</td>
                    <td>{entry.amount} SAR</td>
                    <td style={{ color: getStatusColor(entry.status) }}>
                      {entry.status.charAt(0).toUpperCase() +
                        entry.status.slice(1)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="no-history-message">
                    No recharge history available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RechargeRequest;
