import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Comission.css";

function Commission() {
  const [commission, setCommission] = useState(null);
  const [updatedCommission, setUpdatedCommission] = useState({
    percentage: "",
  });
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isHovered, setIsHovered] = useState(false);

  // Fetch current commission details from your backend using async/await
  useEffect(() => {
    const fetchCommission = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/commission`); 
        if (!response.ok) {
          throw new Error("Failed to fetch commission data");
        }
        const data = await response.json();
        console.log(data);
        setCommission(data);
        setUpdatedCommission({
          percentage: data, 
        });
      } catch (error) {
        console.error("Error fetching commission data:", error);
      }
    };

    fetchCommission();
  }, [BASE_URL]);

  // Handle changes in input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedCommission((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission to update commission using async/await
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the new percentage is different from the current commission
    if (updatedCommission.percentage === commission) {
      toast.warn("No changes made to the processing fees.");
      return; 
    }

    try {
      const response = await fetch(`${BASE_URL}/api/commission`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedCommission),
      });
      if (!response.ok) {
        throw new Error("Failed to update commission");
      }
      const data = await response.json();
      toast.success("Charges updated successfully!");
      setCommission(data); 
    } catch (error) {
      console.error("Error updating commission:", error);
    }
  };

  return (
    <div className="paypal-charges-container">
    <div className="inside-paypal-charges">
    <h2 className="paypal-charges-title">Paypal Processing Charges</h2>
    {commission !== null ? (
      <form onSubmit={handleSubmit} className="paypal-charges-form">
        <div className="form-group">
          <label htmlFor="percentage" className="form-label">
            Percentage
          </label>
          <input
            id="percentage"
            className="form-input"
            type="number"
            name="percentage"
            value={updatedCommission.percentage}
            onChange={handleInputChange}
            required
          />
        </div>
        <button
          type="submit"
          className={`update-button ${isHovered ? "hovered" : ""}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Update Charges
        </button>
      </form>
    ) : (
      <p className="loading-text">Loading commission data...</p>
    )}
    <ToastContainer position="top-right" />
    </div>
  </div>
  )
}

export default Commission;