
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./components/Ebill/Landing.js";
import Login from "./components/login";
import Register from "./components/register";
import Dashboard from "./components/dashboard/Dash";
import Features from "./components/Ebill/Features.js";
import ContactUs from './components/Ebill/ContactUs.js';
import GuideFaqs from './components/Ebill/GuideFaqs.js';
import Forgot from "./components/forgot";
import InvoiceDetail from "../src/components/dashboard/InvoiceDetail";
import Plans from "./components/Ebill/Plans.js";
import Privacypolicy from "./components/Ebill/Privacypolicy.js";
import Refundpolicy from "./components/Ebill/Refundpolicy.js";
import Termtouse from "./components/Ebill/Termtouse.js";
import Demo from "./components/Ebill/Demo.js";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Landing />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard/*" element={<Dashboard />} /> 
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/InvoiceDetail" element={<InvoiceDetail/>} />
        <Route path="/features" element={<Features/>} />
        <Route path="/contactus" element={<ContactUs />} /> 
        <Route path="/guide-faqs" element={<GuideFaqs />} />
        <Route path="/pricing" element={<Plans/>}/>
         <Route path="/privacy-policy" element={<Privacypolicy/>}/>
         <Route path="/refund-policy" element={<Refundpolicy/>}/>
         <Route path="/term-to-use" element={<Termtouse/>}/>
         <Route path="/demo" element={<Demo/>}/>
      </Routes>
    </Router>
  );
}
export default App





  
