import React, { useEffect, useState } from "react";
import "./Coupons.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const initialCouponState = {
    code: "",
    discount: 0,
    min_cart_value: 0,
    expiry_date: "",
    per_user_limit: 0,
    discount_type: "percentage",
    status: "active",
    start_date: "",
  };
  const [newCoupon, setNewCoupon] = useState(initialCouponState);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/coupons`);
      if (!response.ok) {
        throw new Error("Failed to fetch coupon data");
      }
      const data = await response.json();
      setCoupons(data);
    } catch (error) {
      console.error("Error fetching coupon data:", error);
    }
  };

  const handleEdit = (id, field, value) => {
    setCoupons((prev) =>
      prev.map((coupon) =>
        coupon.coupon_id === id ? { ...coupon, [field]: value } : coupon
      )
    );
  };

  const saveCoupon = async (coupon) => {
    try {
      const response = await fetch(`${BASE_URL}/api/coupons/${coupon.coupon_id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(coupon),
      });

      if (!response.ok) {
        throw new Error("Failed to update coupon");
      }
      const data = await response.json();
      toast.success(data.message);
      fetchCoupons();
    } catch (error) {
      console.error("Error saving coupon:", error);
    }
  };

  const deleteCoupon = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this coupon?");
    if (!userConfirmed) {
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/api/coupons/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete coupon");
      }
      toast.success("Coupon Deleted");
      fetchCoupons();
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };

  const addNewCoupon = async () => {
    const hasEmptyField = Object.values(newCoupon).some(
      (value) => value === "" || value === 0
    );
    const isUnchanged = Object.keys(initialCouponState).every(
      (key) => newCoupon[key] === initialCouponState[key]
    );
  
    if (hasEmptyField) {
      toast.warning("Please fill in all the fields to create a coupon!");
      return; 
    }
  
    if (isUnchanged) {
      toast.warning("Please make changes to create a new coupon!");
      return; 
    }
    try {
      const response = await fetch(`${BASE_URL}/api/coupons`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newCoupon),
      });

      if (!response.ok) {
        throw new Error("Failed to add new coupon");
      }
      const data = await response.json();
      toast.success(data.message);
      fetchCoupons();
      setNewCoupon(initialCouponState);
    } catch (error) {
      console.error("Error adding new coupon:", error);
    }
  };

  const renderCoupons = (couponsList) =>
    couponsList.map((coupon) => (
      <div key={coupon.coupon_id} className="coupon-card">
        <div className="coupon-fields">
          <label>
            Coupon ID:<span className="required-star">*</span>
            <input type="text" value={coupon.coupon_id} readOnly />
          </label>

          <label>
            Code:<span className="required-star">*</span>
            <input
              type="text"
              value={coupon.code}
              onChange={(e) => handleEdit(coupon.coupon_id, "code", e.target.value)}
            />
          </label>

          <label>
            Discount:<span className="required-star">*</span>
            <input
              type="number"
              value={coupon.discount}
              onChange={(e) =>
                handleEdit(coupon.coupon_id, "discount", parseFloat(e.target.value))
              }
            />
          </label>

          <label>
            Min Cart Value:<span className="required-star">*</span>
            <input
              type="number"
              value={coupon.min_cart_value}
              onChange={(e) =>
                handleEdit(coupon.coupon_id, "min_cart_value", parseFloat(e.target.value))
              }
            />
          </label>

          <label>
            Expiry Date:<span className="required-star">*</span>
            <input
              type="date"
              value={coupon.expiry_date.split("T")[0]}
              onChange={(e) => handleEdit(coupon.coupon_id, "expiry_date", e.target.value)}
              style={{display:"flex",flex:"1 1 200px"}}
            />

          </label>

          <label>
            Per User Limit:<span className="required-star">*</span>
            <input
              type="number"
              value={coupon.per_user_limit}
              onChange={(e) =>
                handleEdit(coupon.coupon_id, "per_user_limit", parseInt(e.target.value))
              }
            />
          </label>

          <label>
            Discount Type:<span className="required-star">*</span>
            <select
              value={coupon.discount_type}
              onChange={(e) =>
                handleEdit(coupon.coupon_id, "discount_type", e.target.value)
              }
              style={{display:"flex",flex:"1 1 200px"}}
            >
              <option value="percentage">Percentage</option>
              <option value="flat">Flat</option>
            </select>
          </label>

          <label>
            Status:<span className="required-star">*</span>
            <select
              value={coupon.status}
              onChange={(e) => handleEdit(coupon.coupon_id, "status", e.target.value)}
              style={{display:"flex",flex:"1 1 200px"}}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </label>

          <div className="buttons-containers">
            <button onClick={() => saveCoupon(coupon)} className="save-button">
              Save
            </button>
            <button onClick={() => deleteCoupon(coupon.coupon_id)} className="delete-button">
              Delete
            </button>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="coupons-container">
      <h2>Coupons</h2>
      {renderCoupons(coupons)}
      <div className="new-coupon">
        <h2>Add New Coupon</h2>
        <div  className="coupon-card">
        <div className="coupon-fields">
          <label>
            Code:<span className="required-star">*</span>
            <input
              type="text"
              value={newCoupon.code}
              onChange={(e) => setNewCoupon({ ...newCoupon, code: e.target.value })}
              style={{display:"flex",flex:"1 1 200px"}}
              required
            />
          </label>

          <label>
            Discount:<span className="required-star">*</span>
            <input
              type="number"
              value={newCoupon.discount}
              onChange={(e) =>
                setNewCoupon({ ...newCoupon, discount: parseFloat(e.target.value) })
              }
              style={{display:"flex",flex:"1 1 200px"}}
              required
            />
          </label>

          <label>
            Min Cart Value:<span className="required-star">*</span>
            <input
              type="number"
              value={newCoupon.min_cart_value}
              onChange={(e) =>
                setNewCoupon({ ...newCoupon, min_cart_value: parseFloat(e.target.value) })
              }
              style={{display:"flex",flex:"1 1 200px"}}
              required
            />
          </label>

          <label>
            Expiry Date:<span className="required-star">*</span>
            <input
              type="date"
              value={newCoupon.expiry_date}
              onChange={(e) => setNewCoupon({ ...newCoupon, expiry_date: e.target.value })}
              style={{display:"flex",flex:"1 1 200px"}}
              required
            />
          </label>
          <label>
            Start Date:<span className="required-star">*</span>
            <input
              type="date"
              value={newCoupon.start_date}
              onChange={(e) => setNewCoupon({ ...newCoupon, start_date: e.target.value })}
              style={{display:"flex",flex:"1 1 200px"}}
              required
            />
          </label>

          <label>
            Per User Limit:<span className="required-star">*</span>
            <input
              type="number"
              value={newCoupon.per_user_limit}
              onChange={(e) =>
                setNewCoupon({ ...newCoupon, per_user_limit: parseInt(e.target.value) })
              }
              style={{display:"flex",flex:"1 1 200px"}}
              required
            />
          </label>

          <label>
            Discount Type:<span className="required-star">*</span>
            <select
              value={newCoupon.discount_type}
              onChange={(e) => setNewCoupon({ ...newCoupon, discount_type: e.target.value })}
              style={{display:"flex",flex:"1 1 200px"}}
              required
            >
              <option value="percentage">Percentage</option>
              <option value="flat">Flat</option>
            </select>
          </label>

          <label>
            Status:<span className="required-star">*</span>
            <select
              value={newCoupon.status}
              onChange={(e) => setNewCoupon({ ...newCoupon, status: e.target.value })}
              style={{display:"flex",flex:"1 1 200px"}}
              required
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </label>
        </div>
        <button onClick={addNewCoupon} className="save-button">
          Add Coupon
        </button>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default Coupons;
