import React, { useState, useEffect } from 'react';
import './DemoData.css'; 
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

function DemoData() {
  const [formData, setFormData] = useState({
    url: '',
    id: '',
    password: ''
  });

  const [initialData, setInitialData] = useState({
    url: '',
    id: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.url || !formData.id || !formData.password) {
      toast.warn('All fields (Url, Id, Password) are required.');
      return;
    }

    if (
      formData.url === initialData.url &&
      formData.id === initialData.id &&
      formData.password === initialData.password
    ) {
      toast.warn('No changes made to the data.');
      return;
    }

    try {
      // Send data to the backend to save it
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/demo`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), 
      });

      if (!response.ok) {
        throw new Error('Failed to update demo data');
      }

      const result = await response.json();
      toast.success('Demo data updated successfully!');
      setInitialData(formData);
    } catch (error) {
      toast.error('Error saving demo data: ' + error.message);
    }
  };

  // Fetch initial demo data
  useEffect(() => {
    const fetchDemoData = async () => {
      const BASE_URL = process.env.REACT_APP_BASE_URL;
      try {
        const response = await fetch(`${BASE_URL}/api/demo`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setInitialData(data.result); 
        setFormData(data.result); 
      } catch (err) {
        console.error('Error fetching demo data:', err);
      }
    };

    fetchDemoData();
  }, []);

  return (
    <div className="demo-form-container">
      <div style={{ padding: '15px', borderRadius: '8px', border: '1px solid #ddd' }}>
        <h2 className="demo-form-title">Manage Demo Data</h2>
        <form className="demo-form" onSubmit={handleSubmit}>
          <div className="demo-form-group">
            <label className="demo-form-label">Url:</label>
            <input
              type="text"
              name="url" 
              value={formData.url} 
              onChange={handleChange}
              className="demo-form-input"
              placeholder="Enter Url"
            />
          </div>
          <div className="demo-form-group">
            <label className="demo-form-label">Id:</label>
            <input
              type="text"
              name="id" 
              value={formData.id} 
              onChange={handleChange}
              className="demo-form-input"
              placeholder="Enter Id"
            />
          </div>
          <div className="demo-form-group">
            <label className="demo-form-label">Password:</label>
            <input
              type="password"
              name="password" 
              value={formData.password} 
              onChange={handleChange}
              className="demo-form-input"
              placeholder="Enter Password"
            />
          </div>
          <button type="submit" className="demo-form-button">
            Save Changes
          </button>
        </form>
      </div>
      <ToastContainer position="top-right" /> 
    </div>
  );
}

export default DemoData;
