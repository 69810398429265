import React, { useState, useEffect } from "react";
import "./WalletRechargeRequests.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function WalletRechargeRequests() {
  const [rechargeRequests, setRechargeRequests] = useState([]);
  const [previewData, setPreviewData] = useState(null);
  const [activeRequestId, setActiveRequestId] = useState(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // Fetch wallet recharge requests when the component mounts
  useEffect(() => {
    const fetchRechargeRequests = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/wallet-recharge-requests`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch recharge requests");
        }
        const data = await response.json();
        setRechargeRequests(data);
      } catch (err) {
        toast.error(err.message);
        console.error("Error fetching recharge requests:", err);
      }
    };

    fetchRechargeRequests();
  }, [BASE_URL]);

  // Function to handle PDF preview
  const handlePreview = (pdfPath, userEmail, senderName, requestId) => {
    setPreviewData({ pdfPath, userEmail, senderName });
    setActiveRequestId(requestId);
  };

  // Function to close the preview
  const closePreview = () => {
    setPreviewData(null);
    setActiveRequestId(null);
  };

  // Function to handle verification
  const handleVerify = async (requestId, action) => {
    try {
      const response = await fetch(`${BASE_URL}/api/verify-recharge-request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requestId }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error("Failed to verify the request");
      }
      const updatedRequests = rechargeRequests.map((request) =>
        request.request_id === requestId
          ? { ...request, status: action }
          : request
      );

      setRechargeRequests(updatedRequests);
      toast.success(data.message);
    } catch (err) {
      toast.error("Error verifying the request:", err);
    }
  };

  // Function to handle approve or cancel
  const handleApproveCancel = async (requestId, action) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/approve-cancel-recharge-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ requestId }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to ${action} the request`);
      }
      // Update state to reflect the new status
      const updatedRequests = rechargeRequests.map((request) =>
        request.request_id === requestId
          ? { ...request, status: action }
          : request
      );

      setRechargeRequests(updatedRequests); // Update the state
      toast.error("Request Cancelled !", {
        icon: <span style={{ color: 'red', fontSize: '20px', fontWeight: 'bold' }}>X</span>
      });
    } catch (err) {
      console.error(`Error ${action} the request:`, err);
    }
  };

  // Function to determine the color based on status
  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "green";
      case "rejected":
        return "red";
      case "pending":
        return "orange";
      default:
        return "black";
    }
  };

  return (
    <div className="wallet-recharge-requests-container">
      <h2>Wallet Recharge Requests</h2>
      <div
        style={{
          padding: "10px",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <table className="recharge-requests-table">
          <thead>
            <tr>
              <th>User Email</th>
              <th>Sender Name</th>
              <th>Amount</th>
              <th>Receipt</th>
              <th>Request Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {rechargeRequests.length > 0 ? (
              rechargeRequests.map((request) => (
                <tr key={request.id}>
                  <td>{request.user_email}</td>
                  <td>{request.sender_name}</td>
                  <td>{request.amount}</td>
                  <td>
                    {request.receipt_path && (
                      <button
                        onClick={() =>
                          handlePreview(
                            request.receipt_path,
                            request.user_email,
                            request.sender_name,
                            request.id
                          )
                        }
                        className={
                          activeRequestId === request.id
                            ? "view-receipt active"
                            : "view-receipt"
                        }
                      >
                        View Receipt
                      </button>
                    )}
                  </td>
                  <td>
                    {(() => {
                      const date = new Date(request.created_at);
                      const formattedDate = `${date.getDate()}/${String(
                        date.getMonth() + 1
                      ).padStart(2, "0")}/${date.getFullYear()}`;
                      const formattedTime = `${String(date.getHours()).padStart(
                        2,
                        "0"
                      )}:${String(date.getMinutes()).padStart(2, "0")}:${String(
                        date.getSeconds()
                      ).padStart(2, "0")}`;
                      return `${formattedDate} ${formattedTime}`;
                    })()}
                  </td>

                  <td style={{ color: getStatusColor(request.status) }}>
                    {request.status}
                  </td>
                  <td>
                    {request.status === "approved" ? (
                      <button disabled className="verify-button">
                        Approved
                      </button>
                    ) : request.status === "rejected" ? (
                      <button disabled className="verify-button">
                        Rejected
                      </button>
                    ) : request.status === "pending" ? (
                      <>
                        <button
                          onClick={() =>
                            handleVerify(request.request_id, "approved")
                          }
                          className="approve-button"
                          style={{ backgroundColor: "green" }}
                        >
                          Approve
                        </button>
                        <button
                          onClick={() =>
                            handleApproveCancel(request.request_id, "rejected")
                          }
                          className="cancel-button"
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "brown",
                          }}
                        >
                          Reject
                        </button>
                      </>
                    ) : null}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No recharge requests found.</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* PDF Preview Modal */}
        {previewData && (
          <div className="pdf-preview-modal">
            <div className="modal-content">
              <span className="close-btn" onClick={closePreview}>
                &times;
              </span>
              <h3>
                Receipt for {previewData.senderName} ({previewData.userEmail})
              </h3>
              <iframe
                src={`${BASE_URL}${previewData.pdfPath}`}
                width="100%"
                height="600px"
                title="Receipt Preview"
              />
            </div>
          </div>
        )}
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
}

export default WalletRechargeRequests;
