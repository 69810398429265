import React, { useEffect, useState } from "react";
import "./Invoices.css";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

function Invoices() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const token = Cookies.get("token");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setIsAdmin(decodedToken.isAdmin);
      setUserEmail(decodedToken.email);
    }
  }, [token]);

  useEffect(() => {
    fetchInvoices();
  }, [isAdmin, userEmail]);

  useEffect(() => {
    applyFilters();
  }, [filterText, filterStatus, invoices]);

  const fetchInvoices = async () => {
    const url = isAdmin
      ? `${BASE_URL}/allinvoices`
      : `${BASE_URL}/invoices/${userEmail}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (response.ok) {
        setInvoices(result.Invoicedetail || []);
        setFilteredInvoices(result.Invoicedetail || []);
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const applyFilters = () => {
    const filtered = invoices.filter((invoice) => {
      const matchesName =
        filterText === "" ||
        invoice.user_name?.toLowerCase().includes(filterText.toLowerCase());
      const matchesStatus =
        filterStatus === "" ||
        invoice.status.toLowerCase() === filterStatus.toLowerCase();

      return matchesName && matchesStatus;
    });

    setFilteredInvoices(filtered);
  };

  return (
    <div className="invoice-list">
      {/* Filter Section */}
      <div className="filter-section">
        <input
          type="text"
          placeholder="Search by Name"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by Status (Paid/Unpaid)"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
        />
      </div>

      <div className="subscriptions-table">
        <table className="invoice-table">
          <thead>
            <tr>
              <th>No</th>
              {isAdmin ? <th>Customers</th> : null}
              <th>Amount</th>
              <th>Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredInvoices.length === 0 ? (
              <tr>
                <td colSpan={isAdmin ? 6 : 5}>No invoices available</td>
              </tr>
            ) : (
              filteredInvoices.map((invoice, index) => (
                <tr key={invoice.invoice_no}>
                  <td>{index + 1}</td>
                  {isAdmin ? (
                    <td style={{ fontWeight: "600" }}>{invoice.user_name}</td>
                  ) : null}
                  <td>SAR {invoice.amount}</td>
                  <td>
                    <span style={{ fontWeight: "600" }}>
                      {invoice.status === "paid"
                        ? "Paid Date"
                        : "Last Payment Date"}
                      <br />
                    </span>
                    <span>
                      {invoice.status === "paid"
                        ? invoice.payment_date
                        : invoice.last_payment_date}
                    </span>
                  </td>
                  <td>
                    <span
                      className={
                        invoice.status === "paid"
                          ? "status-paid"
                          : "status-unpaid"
                      }
                    >
                      {invoice.status}
                    </span>
                  </td>
                  <td>
                    <Link
                      to="/InvoiceDetail"
                      className="btn view"
                      state={{
                        userEmail: isAdmin ? invoice.user_email : userEmail,
                        invoice_no: invoice.invoice_no,
                      }}
                    >
                      <FaEye style={{ marginRight: "5px" }} />
                      View
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Invoices;
