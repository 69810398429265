import React, { useState, useEffect } from 'react';
import './AccountDetails.css';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function AccountDetails() {
  const [accountDetails, setAccountDetails] = useState({
    accountName: '',
    bankName: '',
    accountNumber: '',
    iban: '',
  });

  const [originalAccountDetails, setOriginalAccountDetails] = useState({
    accountName: '',
    bankName: '',
    accountNumber: '',
    iban: '',
  });
  const BASE_URL = process.env.REACT_APP_BASE_URL;
   useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/bank-details`); 
        if (!response.ok) {
          throw new Error('Failed to fetch bank details');
        }
        const data = await response.json();
        setAccountDetails({
          accountName: data.account_name,
          bankName: data.bank_name,
          accountNumber: data.account_number,
          iban: data.iban,
        });
        setOriginalAccountDetails({
          accountName: data.account_name,
          bankName: data.bank_name,
          accountNumber: data.account_number,
          iban: data.iban,
        });
      } catch (error) {
        console.error('Error fetching bank details:', error);
      }
    };

    fetchBankDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      accountDetails.accountName === originalAccountDetails.accountName &&
      accountDetails.bankName === originalAccountDetails.bankName &&
      accountDetails.accountNumber === originalAccountDetails.accountNumber &&
      accountDetails.iban === originalAccountDetails.iban
    ) {
      toast.warn('No changes detected in bank details');
      return; 
    }
    try {
      const response = await fetch(`${BASE_URL}/api/bank-details`, {
        method: 'PUT', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(accountDetails),
      });
      if (!response.ok) {
        throw new Error('Failed to update bank details');
      }

      const data = await response.json();
      toast.success(data.message);
      setOriginalAccountDetails(accountDetails);
    } catch (error) {
      console.error('Error updating bank details:', error);
      toast.error('Failed to update bank details');
    }
  };

  return (
    <div className="account-details-container">
      <form onSubmit={handleSubmit}>
        <div className="bank-details">
          <h3 className="bank-details-title">Bank Account Details</h3>
          <label>
            <strong>Account name:</strong>
            <input
              type="text"
              name="accountName"
              value={accountDetails.accountName}
              onChange={handleChange}
              className="editable-input"
            />
          </label>
          <label>
            <strong>Bank name:</strong>
            <input
              type="text"
              name="bankName"
              value={accountDetails.bankName}
              onChange={handleChange}
              className="editable-input"
            />
          </label>
          <label>
            <strong>Account number:</strong>
            <input
              type="text"
              name="accountNumber"
              value={accountDetails.accountNumber}
              onChange={handleChange}
              className="editable-input"
            />
          </label>
          <label>
            <strong>IBAN:</strong>
            <input
              type="text"
              name="iban"
              value={accountDetails.iban}
              onChange={handleChange}
              className="editable-input"
            />
          </label>
        </div>
        <div className="actions-buttons">
          <button type="submit" className="submit-button">
            Save Details
          </button>
        </div>
      </form>
      <ToastContainer position="top-right" />
    </div>
  );
}

export default AccountDetails;
