import React from "react";
import { motion } from "framer-motion";
import "./ContactUs.css";
import whatsapp from "./images/whatsapp.png";
import website from "./images/website.png";
import HeaderEbill from "./HeaderEbill";
import FooterEbill from "./FooterEbill";
import ScrollToTop from "./ScrollTop";
import { MdOutlineEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";

function ContactUs() {
  return (
    <>
    <HeaderEbill/>
    <ScrollToTop/>
    <div className="contact-container">
      <h1>Contact Us</h1>
      <div className="lines"></div>
      
      <div className="get-in-touch">
        <h2>Get In Touch</h2>
        <p>
          Your opinions are important to us. Whether it's a simple question or
          a valuable suggestion, we are here 24/7. You can fill up the form here
          or email us directly.
        </p>
      </div>
      
      <div className="contact-details" >
        {/* WhatsApp Scanner (Animated) */}
        <motion.div
          className="whatsappsacaner"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <img src={whatsapp} alt="QR Scanner" />
        </motion.div>

        {/* Location and Contact Information (Animated) */}
        <motion.div
          className="texts"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div style={{border:"1px solid #ddd" ,padding:"10px",borderRadius:"10px"}}>
          <h3>Locations</h3>
          <ul>
            <li>Dabbab Street, Olaya, Riyadh 12251, Kingdom of Saudi Arabia</li>
          </ul>
          <p>
          <MdOutlineEmail color="#0f4b60"/> <a href="mailto:contact@solexnet.com" className="email-link">contact@solexnet.com</a>
          </p>
          <p>
          <FaWhatsapp color="#0f4b60"/> +966 577 711 432
          </p>
          <p>
          <BsTelephone color="#0f4b60"/> +966 112 765 076
          </p>
          <ul>
            <li>Sarsar, Siwan, Bihar 841226 INDIA</li>
          </ul>
          <p>
          <MdOutlineEmail color="#0f4b60"/> <a href="mailto:info@solexnet.com">info@solexnet.com</a>
          </p>
          <p>
          <FaWhatsapp color="#0f4b60"/> +91 731 990 5060
          </p>
          </div>
        </motion.div>

        {/* Website Scanner (Static) */}
        <motion.div
          className="whatsappsacaner"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <img src={website} alt="QR Scanner" />
          
        </motion.div>
      </div>
    </div>
    <FooterEbill/>
    </>
  );
}

export default ContactUs;
